import {SelectionModel} from '@angular/cdk/collections';
import {ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {CreateUserComponent} from '../create-user/create-user.component';
import {UpdateUserComponent} from '../update-user/update-user.component';
import {BulkUploadComponent} from '../bulk-upload/bulk-upload.component';
import {UserTo} from '../../shared/models/userTo';
import {NavigationService} from '../../navigation/navigation.service';
import {UsersService} from '../users.service';
import {OrganizationsService} from '../../organizations/organizations.service';
import {HomeService} from '../../home/home.service';
import {SharedService} from '../../shared/shared.service';
import {Roles} from '../../shared/enums/roles';
import {Subscription} from 'rxjs';
import {ListParentOrganizationsComponent} from "../../organizations/list-parent-organizations/list-parent-organizations.component";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import {Alerts} from '../../shared/enums/alerts';

@Component({
    selector: 'app-list-users',
    templateUrl: './list-users.component.html',
    styleUrls: ['./list-users.component.scss'],
    encapsulation : ViewEncapsulation.None,
})

export class ListUsersComponent implements OnInit {
    readonly USERNAME = 'userName';
    readonly ORGANIZATION = 'org';
    readonly EMAIL = 'email';
    readonly ROLES = 'roles';
    readonly FIRSTNAME = 'firstName';
    readonly LASTNAME = 'lastName';
    readonly OPTIONS = 'options';
    readonly SELECT = 'select';

    @Input() fromSettings: boolean;
    roles = Roles;
    userRole: string = '';
    isLoading: boolean = true;
    isDeletingUser: boolean = false;
    displayFailureMsg: boolean = false;
    dialogRef: MatDialogRef<unknown, any>;
    orgDialogRef: MatDialogRef<unknown, any>;
    All: string = 'All';
    userList: UserTo[] = [];
    allUsers: UserTo[] = [];
    displayedColumns: string[] = [this.USERNAME, this.ORGANIZATION, this.EMAIL, this.ROLES, this.FIRSTNAME, this.LASTNAME, this.OPTIONS, this.SELECT];
    dataSource = new MatTableDataSource<UserTo>(this.userList);
    selection = new SelectionModel<UserTo>(true, []);
    selectedOrg: any;
    selectedOrgName: string;
    selectedUsers: Array<any> = [];
    selectedUser: any;
    selectedUserEmail: string;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    paginatorLength: number;
    private userServiceSubscription: Subscription;
    columnMap: any = {"org":"name"};

    constructor(public dialog: MatDialog,
                public navigation: NavigationService,
                public usersService: UsersService,
                public homeService: HomeService,
                public orgService: OrganizationsService,
                public sharedService: SharedService,
                private cdref: ChangeDetectorRef) {
        this.userServiceSubscription = this.usersService.updateUserList$.subscribe(
            data => {
                if (!this.fromSettings) {
                    this.getAllUsers();
                }
            }
        );
    }

    ngOnInit() {
        if (this.fromSettings) {
            this.usersService.getAllUsers().subscribe({
                next: data => {
                    this.isLoading = false;
                    this.displayFailureMsg = false;
                    if (data) {
                        data.forEach(element => {
                            if (element.userName === this.homeService.userDetails.userName) {
                                this.userList.push(element);
                                this.dataSource = new MatTableDataSource<UserTo>(this.userList);
                            }
                        });
                    }
                },
                error: error => {
                    this.displayFailureMsg = true;
                    this.isLoading = false;
                },
                complete: () => {
                    this.selectedUsers = [];
                }
            });

        } else {
            this.getLoggedInUserData(this.homeService.userDetails);
            this.getAllUsers();
        }
    }

    ngAfterContentChecked() {
         this.cdref.detectChanges();
    }

    selectAllOrgs() {
        if(this.selectedOrgName !== this.All) {
            this.selectedOrgName = this.All;
            this.selectedOrg = undefined
            this.getUserListData(this.selectedOrgName);
        }
    }

    selectOrg() {
        this.orgDialogRef = this.dialog.open(ListParentOrganizationsComponent, {
            data: this.selectedOrg?.clientId
        });
        this.orgDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.selectedOrg = result;
                this.selectedOrgName = result.name;
                this.getUserListData(this.selectedOrgName);
            }
        });
    }

    createUser() {
        this.dialogRef = this.dialog.open(CreateUserComponent, {
            data: this.selectedOrg,
            width: '550px'
        });
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getAllUsers();
            }
        });
    }

    deleteUsers() {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Delete ' + this.selectedUsers.length + ' users?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'No, Cancel',
            showLoaderOnConfirm: true
        }).then((result: any) => {
            Swal.fire({
                title: 'Please Wait...',
                text: 'Deleting users!',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            if (result.value) {
                this.isDeletingUser = true;
                const emailAddresses =  this.selectedUsers.map(user => user.email);
                this.usersService.bulkDeleteUsers(emailAddresses).subscribe({
                    next: data => {
                        this.isDeletingUser = false;
                        Swal.close();
                        this.sharedService.showAlert(Alerts.SUCCESS, data + ' users were successfully deleted!');
                        this.usersService.updateUserList.next();
                    },
                    error: error => {
                        this.isDeletingUser = false;
                        Swal.close();
                        this.sharedService.showAlert(Alerts.ERROR, error.error.message);
                    },
                    complete: () => {
                    }
                });
            } else {
                Swal.close();
            }
        });
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    editUser(object) {
        this.dialogRef = this.dialog.open(UpdateUserComponent, {
            data: object,
            width: '550px'
        });
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (!this.fromSettings) {
                    this.getAllUsers();
                } else {
                    this.userList = []
                    this.userList.push(result);
                    this.dataSource = new MatTableDataSource<UserTo>(this.userList);
                }
            }
        });
    }

    ngOnDestroy() {
        this.userServiceSubscription.unsubscribe();
    }

    bulkUpload() {
        const dialogRef = this.dialog.open(BulkUploadComponent, {
            width: '650px',
            data: this.selectedOrg,
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.ngOnInit();
            }
        });
    }

    getLoggedInUserData(userDetails) {
        this.selectedOrgName = userDetails.org.name;
        this.selectedOrg = userDetails.org;
        this.userRole = (userDetails.roles.includes(this.roles.SUPER)) ?
            this.roles.SUPER : (userDetails.roles.includes(this.roles.ADMIN)) ? this.roles.ADMIN : this.roles.SEARCH;
    }

    getAllUsers() {
        this.usersService.getAllUsers().subscribe({
            next: data => {
                this.allUsers = data;
                this.displayFailureMsg = false;
                this.getUserListData(this.selectedOrgName);
                this.isLoading = false;
            },
            error: error => {
                this.displayFailureMsg = true;
                this.isLoading = false;
            },
            complete: () => { }
        });
    }

    formUserListData(data, orgName) {
        this.userList = data.filter(eachUser => {
            if (eachUser.org && eachUser.org.name === orgName) {
                return eachUser;
            }
        });
        if (this.userList.length > 20) {
            this.paginatorLength = this.userList.length;
        } else {
            this.paginatorLength = 20;
        }
    }

    setUserListData() {
        const currentfilter = this.dataSource.filter;
        this.dataSource = new MatTableDataSource<UserTo>(this.userList);
        this.dataSource.filter = currentfilter;
        this.dataSource.filterPredicate = (data: UserTo, filter: string) =>
            data.userName.trim().toLowerCase().indexOf(filter) !== -1 || data.email.trim().toLowerCase().indexOf(filter) !== -1 ||
            data.firstName.trim().toLowerCase().indexOf(filter) !== -1 || data.lastName.trim().toLowerCase().indexOf(filter) !== -1 ||
            data.org.name.trim().toLowerCase().indexOf(filter) !== -1 || JSON.stringify(data.roles).trim().toLowerCase().indexOf(filter) !== -1;
        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.dataSource.sortingDataAccessor = (data: UserTo, sortHeaderId: string): string => {
            if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
            }

            if (typeof data[sortHeaderId] === 'object') {
                return data[sortHeaderId][this.columnMap[sortHeaderId]].toLocaleLowerCase();
            }

            return data[sortHeaderId];
        };
        this.dataSource.sort = this.sort;
        if ((this.userRole !== this.roles.SUPER)) {
            this.isLoading = false;
        }
    }

    deleteUser(object) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Delete the User "' + object.userName + '"',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'No, Cancel',
        }).then((result) => {
            if (result.value) {
                this.isDeletingUser = true;
                this.usersService.deleteUser(object).subscribe({
                    next: data => {
                        this.isDeletingUser = false;
                        this.sharedService.showAlert('success', 'User ' + object.userName + ' has been deleted.');
                        this.usersService.updateUserList.next();
                    },
                    error: error => {
                        this.isDeletingUser = false;
                        this.sharedService.handleErrors(error,
                            [
                                {"condition": (error.status === 400 && Object.keys(error.error).length > 0),
                                    "msg": error.error[Object.keys(error.error)[0]]
                                        .replace(/</g, '&lt;')
                                        .replace(/>/g, '&gt;')}
                            ], "Unable to delete user");
                    },
                    complete: () => {}
                });
            }
        });
    }

    getUserListData(orgName) {
        if (orgName === this.All) {
            this.userList = this.allUsers;
            if (this.userList.length > 20) {
                this.paginatorLength = this.userList.length;
            } else {
                this.paginatorLength = 20;
            }
        } else {
            this.formUserListData(this.allUsers, orgName);
        }
        this.setUserListData();
    }

    selectUser(user: any, event: any) {
        const index = this.selectedUsers.findIndex(u => u.email === user.email);
        if (event.checked && index === -1) {
            this.selectedUsers.push(user);
        } else if (!event.checked && index !== -1)  {
            this.selectedUsers.splice(index, 1);
        }
    }

    selectAllUsers(event: any) {
        if (event.checked) {
            this.dataSource.filteredData
                .map(user => {
                    if (user.email !== this.homeService.userDetails.email) {
                        this.selectedUsers.push(user);
                    }
                });
        } else {
            this.selectedUsers = [];
        }
    }

    isUserSelected(email: string) {
        const index = this.selectedUsers.findIndex(u => u.email === email);
        return index !== -1;
    }

    syncPrimaryPaginator(event: PageEvent) {
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.pageSize = event.pageSize;
        this.paginator.page.emit(event);
    }
}
